import React from "react"
import { Container } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"

const HeroPagesCenter = ({ intl, theme, content }) => (
    <div className={`hero-pages-center ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <h1>{content.title}</h1>
                <div className={`text`} dangerouslySetInnerHTML={{ __html: content.text }}></div>
            </Container>
        </div>
    </div>
)

export default injectIntl(HeroPagesCenter)
