import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl";
import ImageFluid from "./image-fluid";

const ImageBlock = ({ intl, content }) => (
    <div className={`image-block`}>
        {content.image &&
        <div className={`image`}><ImageFluid alt={content.alt} filename={content.image} /></div>
        }
    </div>
)

export default injectIntl(ImageBlock)
