import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl";

const ParagraphBlock = ({ intl, content }) => (
    <div className={content.alignment ? `paragraph-block ` + content.alignment : `paragraph-block`}>
        {content.title &&
        <h2>{content.title}</h2>
        }
        {content.subtitle &&
        <h3>{content.subtitle}</h3>
        }
        {content.text &&
        <div className={`text`} dangerouslySetInnerHTML={{__html: content.text}}></div>
        }
    </div>
)

export default injectIntl(ParagraphBlock)
